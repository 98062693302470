<template>
    <page-container :title="breadcrumb" :breadcrumb="breadcrumb">
      <div class="bg-white">
        <div class="main-container" style="margin-top: -15px">
          <div class="title">平台使能参数</div>
        </div>
      </div>
      <div>
        <a-tabs default-active-key="1" style="background-color: white" @change="callback">
          <a-tab-pane key="1" tab="区域使能列表">
            <div class="main-container">
              <div class="list-table">
                <div class="table-header">
                  <h3 class="table-title">区域使能参数</h3>
                  <div class="table-btns">
                    <a-icon type="redo" class="after-btn table-refresh" style="float: right;margin-right: 1%;font-size: 20px;margin-bottom: 5px" @click="getTableData()"></a-icon>
                  </div>
                </div>
                <a-table :columns="tableColumns" :data-source="tableData" row-key="paramsid" :loading="tableLoading" :row-class-name="(record, index) => (index%2==0?'table-row-even':'table-row-single')" style="margin-top: 20px;" :pagination="false">
                  <span slot="offworkable" slot-scope="value">
                    <a-tag :color="value=='1'?'green':'red'">{{value=='1'?'使能':'禁用'}}</a-tag>
                  </span>
                  <span slot="vehicleable" slot-scope="value">
                    <a-tag :color="value=='1'?'green':'red'">{{value=='1'?'使能':'禁用'}}</a-tag>
                  </span>
                  <span slot="messageable" slot-scope="value">
                    <a-tag :color="value=='1'?'green':'red'">{{value=='1'?'使能':'禁用'}}</a-tag>
                  </span>
                  <span slot="operation" slot-scope="value, record">
                    <a-button type="link" size="small" @click="updateArea(record,value)">禁用</a-button>
                  </span>

<!--                  <span slot="apporderable" slot-scope="value">-->
<!--                    <a-tag :color="value=='1'?'green':'red'">{{value=='1'?'使能':'禁用'}}</a-tag>-->
<!--                  </span>-->
<!--                  <span slot="alarmable" slot-scope="value">-->
<!--                    <a-tag :color="value=='1'?'green':'red'">{{value=='1'?'使能':'禁用'}}</a-tag>-->
<!--                  </span>-->
<!--                  <span slot="tiringable" slot-scope="value">-->
<!--                    <a-tag :color="value=='1'?'green':'red'">{{value=='1'?'使能':'禁用'}}</a-tag>-->
<!--                  </span>-->
<!--                  <span slot="facevisible" slot-scope="value">-->
<!--                    <a-tag :color="value=='1'?'green':'red'">{{value=='1'?'使能':'禁用'}}</a-tag>-->
<!--                  </span>-->
<!--                  <span slot="facefunction" slot-scope="value">-->
<!--                    <a-tag :color="value=='1'?'green':'red'">{{value=='1'?'使能':'禁用'}}</a-tag>-->
<!--                  </span>-->
                </a-table>
                <a-pagination v-model="currentPage" :total="pagination.total" :page-size="pagination.pageSize" show-size-changer @change="pageChange" @showSizeChange="onShowSizeChange" :show-total="(total, range) => `共${total}条`" style="margin-top: 20px; text-align: right;">
                  <template slot="buildOptionText" slot-scope="props">
                    <span>{{ props.value }}条/页</span>
                  </template>
                </a-pagination>
                  </div>
            </div>
          </a-tab-pane>
            <a-tab-pane key="2" tab="区域使能参数">
              <div class="main-container">
                <div class="right-tabs">
                  <a-form-model ref="modalForm" layout="inline" class="form-in-twocolumns" :label-col="{span:9}" :wrapper-col="{span:14}">
                    <a-form-model-item label="所处区域" prop="areacode">
                      <a-cascader :field-names="{ label: 'areaname', value: 'areacode', children: 'children' }" :options="areaOptions" v-model="areaCascaderSelected" :load-data="areaOnLoadChildren" placeholder="请选择区域" :change-on-select="true" :allow-clear="false"></a-cascader>
                    </a-form-model-item>
                    <a-form-model-item label="设备固定电话" prop="telephone">
                      <a-input v-model="telephone" placeholder="请输入救援固定电话"></a-input>
                    </a-form-model-item>
                    <a-form-model-item label="短信使能" prop="messageable">
                      <a-input v-model="messageable" placeholder="1-使能，0-禁用，默认为0"></a-input>
                    </a-form-model-item>
                    <a-form-model-item label="监控室脱岗" prop="offworkable">
                      <a-input v-model="offworkable" placeholder="1-使能，0-禁用，默认为0"></a-input>
                    </a-form-model-item>
                    <a-form-model-item label="大厅电瓶车" prop="vehicleable">
                      <a-input v-model="vehicleable" placeholder="1-使能，0-禁用，默认为0"></a-input>
                    </a-form-model-item>

<!--                    <a-form-model-item label="app应急救援工单" prop="apporderable">-->
<!--                      <a-input v-model="apporderable" placeholder="1-使能，0-禁用，默认为0"></a-input>-->
<!--                    </a-form-model-item>-->
<!--                    <a-form-model-item label="一键报警工单联动" prop="alarmable">-->
<!--                      <a-input v-model="alarmable" placeholder="1-使能，0-禁用，默认为0"></a-input>-->
<!--                    </a-form-model-item>-->
<!--                    <a-form-model-item label="困人故障短信联动使能" prop="tiringable">-->
<!--                      <a-input v-model="tiringable" placeholder="1-使能，0-禁用，默认为0"></a-input>-->
<!--                    </a-form-model-item>-->
<!--                    <a-form-model-item label="人脸识别可见使能" prop="facevisible">-->
<!--                      <a-input v-model="facevisible" placeholder="1-使能，0-禁用，默认为0"></a-input>-->
<!--                    </a-form-model-item>-->
<!--                    <a-form-model-item label="人脸识别功能使能" prop="facefunction">-->
<!--                      <a-input v-model="facefunction" placeholder="1-使能，0-禁用，默认为0"></a-input>-->
<!--                    </a-form-model-item>-->
<!--                    <a-form-model-item label="一键生成报告机构名称" prop="areadatacenter">-->
<!--                      <a-input v-model="areadatacenter" placeholder=""></a-input>-->
<!--                    </a-form-model-item>-->
                  </a-form-model>
                  <div style="text-align: center">
                    <a-button type="primary" style="margin-top: 1%;margin-bottom: 1%" @click="getAreaParam">参数查询</a-button>
                    <a-button type="primary" style="margin-top: 1%;margin-bottom: 1%;margin-left: 1%" @click="areaParam">参数下发</a-button>
                  </div>
                </div>
              </div>
            </a-tab-pane>
        </a-tabs>
    </div>
    </page-container>
</template>
<script>
import {findParamsListByCondition, findParamsListByConditions, modifyParams} from "A/xtpz";
import {getCache} from "U/index";
import areaselect from '@/mixins/areaselect'
import deptselect from "@/mixins/deptselect";
import {mapState} from "vuex";
import {getUserdepListByCondition, modifyUserdep} from "A/jcgn";
import pagination from '@/mixins/pagination'
export default {
  name: "paramset",
  mixins: [areaselect, deptselect,pagination],
  data() {
    return {
      breadcrumb: [
        {
          name: '系统配置',
          path: ''
        },
        {
          name: '高级设置',
          path: ''
        },
        {
          name: '平台参数',
          path: ''
        },
        {
          name: '事件使能参数',
          path: ''
        },
      ],
      status:false,
      areaable:false,
      usersid:'',
      areacode:'',
      areacodes:'',
      //区域使能参数
      paramsid:'',
      messageable:'',
      telephone:'',
      offworkable:'',
      vehicleable:'',

      apporderable:'',
      alarmable:'',
      alarmtofault:'',
      tiringable:'',
      areadatacenter:'',
      facevisible:'',
      facefunction:'',
      //使用单位使能参数
      userdepid:'',
      umessageable:'',
      uapporderable:'',
      urescueable:'',
      userdepidCascaderSelected: [],
      //维保单位使能参数
      maintenancedepid:'',
      mmessageable:'',
      mapporderable:'',
      mrescueable:'',

      //表格
      tableColumns: [
        {
          title: '区域',
          dataIndex: 'areaname',
          key: 'areaname',
          ellipsis: true,
          align: "center"
        },
        {
          title: '短信',
          dataIndex: 'messageable',
          key: 'messageable',
          ellipsis: true,
          align: "center",
          scopedSlots: { customRender: 'messageable' }
        },
        {
          title: '监控室脱岗短信',
          dataIndex: 'offworkable',
          key: 'offworkable',
          ellipsis: true,
          align: "center",
          scopedSlots: { customRender: 'offworkable' }
        },
        {
          title: '大厅电瓶车短信',
          dataIndex: 'vehicleable',
          key: 'vehicleable',
          ellipsis: true,
          align: "center",
          scopedSlots: { customRender: 'vehicleable' }
        },
        {
          title: '操作',
          key: 'operation',
          width: 60,
          align: 'center',
          scopedSlots: { customRender: 'operation' }
        },
      ],
      tableData: [],
      tableLoading: false,
    }
  },
  computed: {
    ...mapState({
      userInfo: state => state.userInfo
    }),
  },
  watch: {
    areaCascaderSelected(val) {
      if(val && val.length) {
        this.areacode = val[val.length-1];
        this.$refs.modalForm.clearValidate('areacode')
      }else {
        this.formDatas.areacode = '';
      }
    },
    userdepidCascaderSelected(val) {
      if(val && val.length) {
        this.userdepid = val[val.length-1];
        this.$refs.modalForm.clearValidate('userdepid')
      }else{
        this.userdepid = '';
      }
    },
    maintenancedepidCascaderSelected(val) {
      if(val && val.length) {
        this.maintenancedepid = val[val.length-1];
        this.$refs.modalForm.clearValidate('maintenancedepid')
      }else {
        this.maintenancedepid = '';
      }
    },
  },
  created() {
    this.init();
  },
  methods: {
    callback(key) {
      if(key==1){
        this.getTableData(true)
      }else if(key==2){
        this.getUserdepTableData(true,3)
      }else if(key==3){
        this.getUserdepTableData(true,1)
      }
    },
    init() {
      let logininfo = getCache('logininfo', true);
      if (logininfo) {
        this.usersid = logininfo.usersid
        if (this.usersid == 1) {
          this.areaable = true
          this.areacode = ''
        }
      }
      this.getTableData(true)
    },
    updateArea(record,value){
      var that=this
      findParamsListByCondition({areacode: record.areacode}).then(res => {
        if (res && res.returncode == '0') {
          if (record.areacode.length == 2) {
            findParamsListByCondition({grandparentid: res.item[0].paramsid}).then(res => {
              if (res && res.returncode == '0') {
                for (var i = 0; i < res.item.length; i++) {
                  let params = {
                    paramsid: res.item[i].paramsid,
                    messageable: 0,
                    offworkable:0,
                    vehicleable:0,
                  }
                  modifyParams(params)
                }
              }
            })
          } else if (record.areacode.length == 4) {
            findParamsListByCondition({parentid: res.item[0].paramsid}).then(res => {
              if (res && res.returncode == '0') {
                for (var i = 0; i < res.item.length; i++) {
                  let params = {
                    paramsid: res.item[i].paramsid,
                    messageable: 0,
                    offworkable:0,
                    vehicleable:0,
                  }
                  modifyParams(params)
                }
              }
            })
          }else {
            let params = {
              paramsid: res.item[0].paramsid,
              messageable: 0,
              offworkable:0,
              vehicleable:0,
            }
            modifyParams(params).then(res=>{
              if (res && res.returncode == '0') {
                this.getTableData()
              }
            })
          }
        }
      })
      setTimeout(function () {
        that.getTableData()
      }, 1000);
    },
    areaParam() {
      var that=this
      if (this.areaCascaderSelected.length == 1) {
        findParamsListByCondition({areacode: this.areaCascaderSelected[this.areaCascaderSelected.length - 1]}).then(res => {
          if (res && res.returncode == '0') {
            findParamsListByCondition({grandparentid: res.item[0].paramsid}).then(res => {
              if (res && res.returncode == '0') {
                for (var i = 0; i < res.item.length; i++) {
                  let params = {
                    paramsid: res.item[i].paramsid,
                    messageable: this.messageable,
                    telephone: this.telephone,
                    offworkable:this.offworkable,
                    vehicleable:this.vehicleable,
                  }
                  modifyParams(params)
                }
              }
            })
          }
        })
      } else if (this.areaCascaderSelected.length == 2) {
        var len;
        findParamsListByCondition({areacode: this.areaCascaderSelected[this.areaCascaderSelected.length - 1]}).then(res => {
          if (res && res.returncode == '0') {
            findParamsListByCondition({parentid: res.item[0].paramsid}).then(res => {
              if (res && res.returncode == '0') {
                for (var i = 0; i < res.item.length; i++) {
                  len = res.item.length - 1;
                  let params = {
                    paramsid: res.item[i].paramsid,
                    messageable: this.messageable,
                    telephone: this.telephone,
                    offworkable:this.offworkable,
                    vehicleable:this.vehicleable,
                  }
                  modifyParams(params)
                }
              }
            })
          }
        })
      } else {
        findParamsListByCondition({areacode: this.areaCascaderSelected[this.areaCascaderSelected.length - 1]}).then(res => {
          if (res && res.returncode == '0') {
            let params = {
              paramsid: res.item[0].paramsid,
              messageable: this.messageable,
              telephone: this.telephone,
              offworkable:this.offworkable,
              vehicleable:this.vehicleable,
            }
            modifyParams(params).then(res => {
              if (res && res.returncode == '0') {
                this.$message.success("参数配置成功")
              } else {
                this.$message.error("参数配置失败")
              }
            })
          }
        })
      }
      setTimeout(function () {
        that.getTableData()
      }, 1500);
    },
    getAreaParam(){
      findParamsListByCondition({areacode: this.areacode}).then(res => {
        if (res && res.returncode == '0') {
          this.messageable=res.item[0].messageable;
          this.offworkable=res.item[0].offworkable;
          this.vehicleable=res.item[0].vehicleable;
          this.telephone=res.item[0].telephone;
        }
      })
    },
    maintenancedepParam() {
      var that=this
      if (this.maintenancedepidCascaderSelected.length == 1) {
        getUserdepListByCondition({userdepid:this.maintenancedepidCascaderSelected[this.maintenancedepidCascaderSelected.length - 1]}).then(res => {
          if (res && res.returncode == '0') {
            modifyUserdep({
              userdepid: res.item[0].userdepid,
              deptype: res.item[0].deptype,
              userdepname: res.item[0].userdepname,
              levelid: res.item[0].levelid,
              messageable: this.mmessageable,
              apporderable: this.mapporderable,
              rescueable: this.mrescueable
            })
          }
        })
        let params = {
          parentid: this.maintenancedepidCascaderSelected[this.maintenancedepidCascaderSelected.length - 1]
        }
        getUserdepListByCondition(params).then(res => {
          if (res && res.returncode == '0') {
            for (var i = 0; i < res.item.length; i++) {
              modifyUserdep({
                userdepid: res.item[i].userdepid,
                deptype: res.item[i].deptype,
                userdepname: res.item[i].userdepname,
                levelid: res.item[i].levelid,
                messageable: this.mmessageable,
                apporderable: this.mapporderable,
                rescueable: this.mrescueable
              })
              getUserdepListByCondition({parentid: res.item[i].userdepid}).then(res => {
                if (res && res.returncode == '0') {
                  for (var i = 0; i < res.item.length; i++) {
                    modifyUserdep({
                      userdepid: res.item[i].userdepid,
                      deptype: res.item[i].deptype,
                      userdepname: res.item[i].userdepname,
                      levelid: res.item[i].levelid,
                      messageable: this.mmessageable,
                      apporderable: this.mapporderable,
                      rescueable: this.mrescueable
                    })
                  }
                }
              })
            }
          }
        })
      } else if (this.maintenancedepidCascaderSelected.length == 2) {
        getUserdepListByCondition({userdepid: this.maintenancedepidCascaderSelected[1]}).then(res => {
          if (res && res.returncode == '0') {
            modifyUserdep({
              userdepid: res.item[0].userdepid,
              deptype: res.item[0].deptype,
              userdepname: res.item[0].userdepname,
              levelid: res.item[0].levelid,
              messageable: this.mmessageable,
              apporderable: this.mapporderable,
              rescueable: this.mrescueable
            })
          }
        })
        let params = {
          parentid: this.maintenancedepidCascaderSelected[this.maintenancedepidCascaderSelected.length - 1]
        }
        getUserdepListByCondition(params).then(res => {
          if (res && res.returncode == '0') {
            for (var i = 0; i < res.item.length; i++) {
              modifyUserdep({
                userdepid: res.item[i].userdepid,
                deptype: res.item[i].deptype,
                userdepname: res.item[i].userdepname,
                levelid: res.item[i].levelid,
                messageable: this.mmessageable,
                apporderable: this.mapporderable,
                rescueable: this.mrescueable,
              })
            }
          }
        })
      } else {
        let params = {
          userdepid: this.maintenancedepidCascaderSelected[this.maintenancedepidCascaderSelected.length - 1]
        }
        getUserdepListByCondition(params).then(res => {
          if (res && res.returncode == '0') {
            modifyUserdep({
              userdepid: res.item[0].userdepid,
              deptype: res.item[0].deptype,
              userdepname: res.item[0].userdepname,
              levelid: res.item[0].levelid,
              messageable: this.mmessageable,
              apporderable: this.mapporderable,
              rescueable: this.mrescueable
            }).then(res => {
              if (res && res.returncode == '0') {
                this.$message.success("参数配置成功")
              } else {
                this.$message.success("参数配置失败")
              }
            })
          }
        })
      }
      setTimeout(function () {
        that.getUserdepTableData(true,3)
      }, 1000);
    },
    getTableData(firstPage) {
      if (firstPage) {
        this.currentPage = 1;
      }
      this.tableLoading = true;
      let params = {
        messageable:1,
        pageno: this.currentPage,
        pagesize: this.pagination.pageSize
      };
      findParamsListByConditions(params).then(res => {
        this.tableLoading = false;
        if (res && res.returncode == '0') {
          this.tableData = res.item;
          this.pagination.total = res.count;
        }
      }).catch(err => {
        this.tableLoading = false;
      })
    },
    getUserdepTableData(firstPage,deptype) {
      if (firstPage) {
        this.currentPage = 1;
      }
      this.tableLoading = true;
      let params = {
        deptype:deptype,
        rescueable:"1",
        pageno: this.currentPage,
        pagesize: this.pagination.pageSize
      };
      getUserdepListByCondition(params).then(res => {
        this.tableLoading = false;
        if (res && res.returncode == '0') {
          this.tableData = res.item;
          this.pagination.total = res.count;
        }
      }).catch(err => {
        this.tableLoading = false;
      })
    }
  }
}
</script>
<style lang="scss" scoped>
.bg-white {
  background-color: #fff;
}
.title {
  font-weight: bold;
  font-size: 20px;
  padding: 5px 20px;
}
.main-container {
  display: flex;
  padding: 10px;
  .right-tabs{
    flex-grow: 1;
    overflow: auto;
    width: 65%;
    height: auto;
    float: right;
    margin-left: 1%;
    padding-top: 1%;
    background-color: white;
    border-radius: 5px;
  }
  .list-table{
    flex-grow: 1;
    overflow: auto;
    width: 100%;
    height: auto;
    float: right;
    margin-left: 1%;
    margin-right: 1%;
    //padding-top: 1%;
    background-color: white;
    border-radius: 5px;
  }
  .left-tabs {
    flex-shrink: 0;
    width: 35%;
    height: auto;
    padding-top: 1.0%;
    background-color: white;
    border-radius: 5px;
    .table-header{
      .table-title {
        float: left;
        margin-left: 2.0%;
        line-height: 32px;
      }
      .table-btns {
        float: right;
        margin-right: 4%;
      }
    }
  }
}
</style>